/* .scrollable-table {
    overflow-x: auto;
  } */
  
  @media (min-width: 1340px) {
    .scrollable-table::-webkit-scrollbar {
      width: 10px;
      height: 6px;
    }
  
    .scrollable-table::-webkit-scrollbar-track {
      background: transparent;
      border-radius: 10px;
    }
  
    .scrollable-table::-webkit-scrollbar-thumb {
      background: #888; 
      border-radius: 10px;
    }
  
    .scrollable-table::-webkit-scrollbar-thumb:hover {
      background: #555; 
    }
  }